import React from "react"
import { B1DarkGrey, H2MidBlue, standardWrapper } from "../../styles/helpers"
import styled from "styled-components"

const PostTitle = ({ title, date, author }) => {
  const options = { year: "numeric", month: "long", day: "numeric" }
  const postDate = new Date(date).toLocaleDateString(undefined, options)
  return (
    <StyledHeader>
      <div className="wrapper">
        <div className="wrapper__inner">
          <h1>{title}</h1>
          <div className="meta">
            <p>{postDate}</p>
            {" | "}
            <p>{author}</p>
          </div>
        </div>
      </div>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  .wrapper {
    ${standardWrapper};

    &__inner {
      width: 100%;
      padding-bottom: 3rem;

      h1 {
        ${H2MidBlue};
        margin-bottom: 3.5rem;
        font-weight: 600;
      }
      .meta {
        display: flex;

        p {
          ${B1DarkGrey};
          margin: 0;

          &:first-of-type {
            padding-right: 2rem;
          }

          &:last-of-type {
            padding-left: 2rem;
          }
        }
      }
    }
  }
`

export default PostTitle
