import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Btn1One, Btn1Two, colors, standardWrapper } from "../../styles/helpers"

import greyStripe from "../../images/grey-stripe.png"

const PostNav = ({ data }) => {
  return (
    <PostNavStyled>
      <div className="wrapper">
        <nav>
          {data.prev ? (
            <Link to={`/news-updates/${data.prev}`}>
              <span>&lt; </span>
              Previous Article
            </Link>
          ) : (
            <button disabled type="button">
              {" "}
              <span>&lt; </span>
              Previous Article
            </button>
          )}

          <Link className="btn-back" to="/news-updates">
            News + Updates
          </Link>
          {data.next ? (
            <Link to={`/news-updates/${data.next}`}>
              Next Article
              <span>&gt; </span>
            </Link>
          ) : (
            <button disabled type="button">
              {" "}
              Next Article
              <span>&gt; </span>
            </button>
          )}
        </nav>
      </div>
      <div className="bg-color__graphic" />
    </PostNavStyled>
  )
}

const PostNavStyled = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: ${colors.colorShad};

  .bg-color__graphic {
    position: absolute;
    top: -5rem;
    left: -5%;
    padding-bottom: 15rem;
    width: 110%;
    background-image: url(${greyStripe});
    background-size: 100% 100%;
    background-repeat: repeat-x;
    background-position: bottom center;
    z-index: -1;
  }

  .wrapper {
    ${standardWrapper};
  }

  nav {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }

    a,
    button {
      ${Btn1Two};
      margin-bottom: 2rem;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }

    button:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    a.btn-back {
      ${Btn1One};
    }
  }
`

export default PostNav
