import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/post/Hero"
import PostTitle from "../components/post/PostTitle"
import MainContent from "../components/post/MainContent"
import PostNav from "../components/post/PostNav"

const Post = props => {
  const { post } = props.data
  const prevPost = props.pageContext.prev
  const nextPost = props.pageContext.next
  return (
    <Layout>
      <Seo />
      <Hero data={post.post.featuredImage} />
      <PostTitle
        title={post.title}
        date={post.date}
        author={post.post.authorName}
      />
      <MainContent wysiwyg={post.post.mainContent} date={post.date} />
      <PostNav data={{ prev: prevPost, next: nextPost }} />
    </Layout>
  )
}

export const query = graphql`
  query singlePostQuery($slug: String!) {
    seoInfo: wpPost(slug: { eq: $slug }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    post: wpPost(slug: { eq: $slug }) {
      post {
        authorName
        mainContent
        postExcerpt
        featuredImage {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 2500)
            }
          }
        }
      }
      title
      id
      date
      slug
    }
  }
`

export default Post
